<template>
  <v-dialog width="300" v-model="dialog">
    <v-card id="pedirReservaLink">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
      >
        Link pedir reserva:
      </v-card-title>
      <div class="close">
        <v-btn icon @click="resDialog = false">
          <v-icon id="iconClose">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <form
          action
          data-vv-scope="link-form"
          @submit.prevent="validateLink('link-form')"
        >
          <v-text-field
            outlined
            dense
            id="URL"
            hide-details
            data-vv-validate-on="blur"
            :error-messages="errors.collect('reserva-form.tattooer')"
            readonly
            v-model="url"
            data-vv-name="tattooer"
            v-validate="'required'"
          >
          </v-text-field>
          <v-row
            style="width: 100%; margin: 0 auto"
            class="pt-3 mb-3 pr-3"
            justify="end"
          >
            <v-btn
              outlined
              @click="dialog = false"
              class="mr-3"
              style="height: 30px; width: 100px"
              elevation="0"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              type="submit"
              elevation="0"
              style="height: 30px; width: 100px !important"
              ><v-icon small style="margin-right: 5px">$save</v-icon
              >{{ $t("save", { name: "" }) }}</v-btn
            >
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      url: this.$store.getters["auth/getLaborExange"],
    };
  },
  computed: {
    reservation_system: {
      get() {
        return this.$store.getters["auth/getLaborExange"];
      },
      set(value) {
        //llamamos a la funcion de crear setting
        this.settingUpdate({ key: "labor_exange", value }).then((data) => {
          this.$alert($t("activated"));
        });
      },
    },
  },
  methods: {
    validateLink(scope) {
      this.errors.clear();

      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.reservation();
        } else {
        }
      });
    },
    open() {
      this.dialog = true;
      this.url = null;
    },
  },
};
</script>